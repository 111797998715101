.experience {
  height: 100%;
  width: 100%;
}

.experience h1 {
  color: #ffa500;
}

.expList {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: auto;
  column-gap: 7.5%;
  row-gap: 2.5rem;
  list-style: none;
  padding: 0;
}

.expList li {
  display: inline-block;
  min-height: 10rem;
  width: 90%;
  background-color: #21262d;
  border-radius: 3px;
  padding: 5%;
}

.tileTitle {
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
}

.tileInfo {
  font-size: 0.9rem;
  font-style: italic;
  color: #8b949e;
}

.tileDescription {
  font-size: 0.9rem;
  color: #8b949e;
}

.tileLink {
  display: inline;
  color: white;
  text-decoration: none;
  border-bottom: 1px solid white;
}

/* Media Queries */

@media only screen and (max-width: 1200px) {
  .expList {
    grid-template-columns: 50% 50%;
    column-gap: 5%;
  }
}

@media only screen and (max-width: 600px) {
  .experience {
    height: auto;
    margin-left: 5%;
    width: 95%;
  }

  .expList {
    grid-template-columns: 95%;
    row-gap: 1rem;
  }

  .expList li {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
}
