.homeBackground {
  background-image: url("../media/bgImg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px;
  height: 100%;
  min-height: fit-content;
  width: 100%;
  padding: 2.5%;
}

.home {
  display: grid;
  grid-template-columns: 50% 50%;
}

.person {
  margin-top: 8%;
  text-align: center;
}

.name {
  margin-top: 3rem;
  color: #ffa500;
  font-size: 2.5rem;
}

.title {
  font-size: 1.2rem;
  color: #8b949e;
}

.introduction {
  margin-top: 3rem;
  padding: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

/* Media Queries */

@media only screen and (max-width: 1200px) {
  .name {
    margin-top: 4rem;
  }
}

@media only screen and (max-width: 600px) {
  .homeBackground {
    padding: 0;
    border-radius: 0;
  }

  .home {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    margin: 0 10% 0 10%;
  }

  .person {
    margin-top: 0;
  }

  .introduction {
    margin-top: 1rem;
    padding: 0.5rem;
  }
}
