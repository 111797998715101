.appMain {
  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
  grid-template-rows: auto;
  column-gap: 5%;
  row-gap: 5rem;
  max-width: 1920px;
  margin: 0 auto;
}

.appSkills {
  grid-column: 1;
  grid-row: 1 / 5;
}

.appHome {
  grid-column: 2 / 5;
  grid-row: 1;
}

.appExp {
  grid-column: 2 / 5;
  grid-row: 2;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.7);
}

/* Media Queries */

@media only screen and (max-width: 1200px) {
  html {
    font-size: 14px;
  }

  .appMain {
    grid-template-columns: 27.5% 27.5% 27.5%;
  }

  .appHome {
    grid-column: 1 / 4;
    grid-row: 1;
  }

  .appExp {
    grid-column: 1 / 4;
    grid-row: 3;
  }

  .appSkills {
    grid-column: 1 / 4;
    grid-row: 2;
  }
}
@media only screen and (max-width: 600px) {
  html {
    font-size: 12px;
  }

  .appMain {
    grid-template-columns: 100%;
    row-gap: 1rem;
  }

  .appHome {
    grid-column: 1;
  }

  .appExp {
    grid-column: 1;
  }

  .appSkills {
    grid-column: 1;
  }
}
