body {
  margin: 2rem 5% 0 5%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #0d1117;
  color: #c9d1d9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 600px) {
  body {
    margin: 0;
  }
}

.appMain {
  display: grid;
  grid-template-columns: 20% 20% 20% 20%;
  grid-template-rows: auto;
  grid-column-gap: 5%;
  column-gap: 5%;
  grid-row-gap: 5rem;
  row-gap: 5rem;
  max-width: 1920px;
  margin: 0 auto;
}

.appSkills {
  grid-column: 1;
  grid-row: 1 / 5;
}

.appHome {
  grid-column: 2 / 5;
  grid-row: 1;
}

.appExp {
  grid-column: 2 / 5;
  grid-row: 2;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.7);
}

/* Media Queries */

@media only screen and (max-width: 1200px) {
  html {
    font-size: 14px;
  }

  .appMain {
    grid-template-columns: 27.5% 27.5% 27.5%;
  }

  .appHome {
    grid-column: 1 / 4;
    grid-row: 1;
  }

  .appExp {
    grid-column: 1 / 4;
    grid-row: 3;
  }

  .appSkills {
    grid-column: 1 / 4;
    grid-row: 2;
  }
}
@media only screen and (max-width: 600px) {
  html {
    font-size: 12px;
  }

  .appMain {
    grid-template-columns: 100%;
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .appHome {
    grid-column: 1;
  }

  .appExp {
    grid-column: 1;
  }

  .appSkills {
    grid-column: 1;
  }
}

.homeBackground {
  background-image: url(/static/media/bgImg.fd66e9ad.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px;
  height: 100%;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
  width: 100%;
  padding: 2.5%;
}

.home {
  display: grid;
  grid-template-columns: 50% 50%;
}

.person {
  margin-top: 8%;
  text-align: center;
}

.name {
  margin-top: 3rem;
  color: #ffa500;
  font-size: 2.5rem;
}

.title {
  font-size: 1.2rem;
  color: #8b949e;
}

.introduction {
  margin-top: 3rem;
  padding: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

/* Media Queries */

@media only screen and (max-width: 1200px) {
  .name {
    margin-top: 4rem;
  }
}

@media only screen and (max-width: 600px) {
  .homeBackground {
    padding: 0;
    border-radius: 0;
  }

  .home {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    margin: 0 10% 0 10%;
  }

  .person {
    margin-top: 0;
  }

  .introduction {
    margin-top: 1rem;
    padding: 0.5rem;
  }
}

.experience {
  height: 100%;
  width: 100%;
}

.experience h1 {
  color: #ffa500;
}

.expList {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: auto;
  grid-column-gap: 7.5%;
  column-gap: 7.5%;
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem;
  list-style: none;
  padding: 0;
}

.expList li {
  display: inline-block;
  min-height: 10rem;
  width: 90%;
  background-color: #21262d;
  border-radius: 3px;
  padding: 5%;
}

.tileTitle {
  margin: 0 0 0.5rem 0;
  font-size: 1.1rem;
}

.tileInfo {
  font-size: 0.9rem;
  font-style: italic;
  color: #8b949e;
}

.tileDescription {
  font-size: 0.9rem;
  color: #8b949e;
}

.tileLink {
  display: inline;
  color: white;
  text-decoration: none;
  border-bottom: 1px solid white;
}

/* Media Queries */

@media only screen and (max-width: 1200px) {
  .expList {
    grid-template-columns: 50% 50%;
    grid-column-gap: 5%;
    column-gap: 5%;
  }
}

@media only screen and (max-width: 600px) {
  .experience {
    height: auto;
    margin-left: 5%;
    width: 95%;
  }

  .expList {
    grid-template-columns: 95%;
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .expList li {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.skills {
  background-color: #21262d;
  height: auto;
  border-radius: 3px;
}

/* Skills Header */
.skillsHeader {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 10% 0 10%;
  padding-bottom: 20%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
}

.profilePic {
  grid-column: 1/4;
  width: 60%;
  margin: 2rem 0 0 50%;
  transform: translateX(-50%);
}

.swedishIcon,
.englishIcon,
.italianIcon {
  width: 50%;
  margin: 1rem 0 0 50%;
  transform: translateX(-50%);
  cursor: pointer;
  transition: transform 0.25s;
}

.swedishIcon:hover,
.englishIcon:hover,
.italianIcon:hover {
  transform: translateX(-50%) scale(1.2);
}

.hexagon {
  -webkit-clip-path: polygon(50% 0, 92.5% 25%, 92.5% 75%, 50% 100%, 7.5% 75%, 7.5% 25%);
          clip-path: polygon(50% 0, 92.5% 25%, 92.5% 75%, 50% 100%, 7.5% 75%, 7.5% 25%);
}

/* About */
.about {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 2.5rem 2.5rem 2.5rem;
  padding: 1rem 0 1rem 0;
  margin: 0 10% 0 10%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.aboutQ {
  text-align: left;
}

.aboutA {
  text-align: right;
  font-style: italic;
  color: #8b949e;
}

/* Languages */
.languages {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 10%;
  column-gap: 10%;
  padding: 1rem;
  margin: 5% 10% 0 10%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
}

.languages li {
  margin: 0.25rem auto;
}

.swedishProgress,
.englishProgress,
.italianProgress {
  display: inline-block;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
}

.swedishProgress {
  background: conic-gradient(#ffa500 360deg, #21262d 0deg);
}

.englishProgress {
  background: conic-gradient(#ffa500 360deg, #21262d 0deg);
}

.italianProgress {
  background: conic-gradient(#ffa500 18deg, #21262d 18deg);
}

.languages p {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  background-color: #21262d;
  align-items: center;
  font-size: 0.9rem;
  margin: 0.25rem;
  line-height: 3.5rem;
}

/* Skillbars */
.skillBars {
  list-style: none;
  padding: 1rem 0 1rem 0;
  margin: 0 10% 0 10%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.skillBars li {
  margin-bottom: 1rem;
}

.skillTitle {
  text-align: left;
  font-size: 0.9rem;
  margin: 0;
}

.progressBar {
  background-color: #0d1117;
  border-radius: 1rem;
}

.progressBarProgress {
  height: 0.8rem;
  background-color: #ffa500;
  color: #0d1117;
  border-radius: 1rem;
  font-size: 0.6rem;
  font-weight: bold;
  text-align: center;
}

/* Contact */
.contact {
  display: grid;
  grid-template-columns: 20% 20% 20%;
  grid-column-gap: 20%;
  column-gap: 20%;
  list-style: none;
  text-align: center;
  padding: 0 25% 0 25%;
}

.contact li {
  background-color: #0d1117;
  border-radius: 0.4rem;
  height: 2.5rem;
  width: 2.5rem;
}

.contactIcon {
  height: 2.5rem;
  filter: invert(1);
}

.contact p {
  height: auto;
  width: auto;
}

.contactInfo {
  color: #8b949e;
  font-size: 0.8rem;
  text-align: center;
  padding-bottom: 3rem;
}

/* Media Queries */

@media only screen and (max-width: 1200px) {
  .skills {
    height: auto;
    width: 105%;
    margin: 2rem 0 0 0%;
  }

  .about,
  .languages,
  .skillBars,
  .contactInfo {
    margin-left: 20%;
    margin-right: 20%;
  }

  .about {
    font-size: 1.2rem;
  }

  .languages li {
    margin: 0 40% 0.5rem 40%;
  }

  .swedishProgress,
  .englishProgress {
    height: 3.5rem;
    width: 3.5rem;
  }

  .languages p {
    height: 3rem;
    width: 3rem;
    line-height: 3rem;
    margin: 0.25rem;
  }

  .contact {
    padding: 0 35% 0 35%;
  }
}

@media only screen and (max-width: 600px) {
  .skills {
    width: 90%;
    margin: 0 0 0 5%;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .languages li {
    margin: 0 35% 0.5rem 35%;
  }

  .languages p {
    background-color: #0d1117;
  }

  .progressBar,
  .progressBarProgress {
    height: 1rem;
    font-size: 0.7rem;
  }
}

