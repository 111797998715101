.skills {
  background-color: #21262d;
  height: auto;
  border-radius: 3px;
}

/* Skills Header */
.skillsHeader {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 10% 0 10%;
  padding-bottom: 20%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
}

.profilePic {
  grid-column: 1/4;
  width: 60%;
  margin: 2rem 0 0 50%;
  transform: translateX(-50%);
}

.swedishIcon,
.englishIcon,
.italianIcon {
  width: 50%;
  margin: 1rem 0 0 50%;
  transform: translateX(-50%);
  cursor: pointer;
  transition: transform 0.25s;
}

.swedishIcon:hover,
.englishIcon:hover,
.italianIcon:hover {
  transform: translateX(-50%) scale(1.2);
}

.hexagon {
  clip-path: polygon(50% 0, 92.5% 25%, 92.5% 75%, 50% 100%, 7.5% 75%, 7.5% 25%);
}

/* About */
.about {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 2.5rem 2.5rem 2.5rem;
  padding: 1rem 0 1rem 0;
  margin: 0 10% 0 10%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.aboutQ {
  text-align: left;
}

.aboutA {
  text-align: right;
  font-style: italic;
  color: #8b949e;
}

/* Languages */
.languages {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  column-gap: 10%;
  padding: 1rem;
  margin: 5% 10% 0 10%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
}

.languages li {
  margin: 0.25rem auto;
}

.swedishProgress,
.englishProgress,
.italianProgress {
  display: inline-block;
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
}

.swedishProgress {
  background: conic-gradient(#ffa500 360deg, #21262d 0deg);
}

.englishProgress {
  background: conic-gradient(#ffa500 360deg, #21262d 0deg);
}

.italianProgress {
  background: conic-gradient(#ffa500 18deg, #21262d 18deg);
}

.languages p {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  background-color: #21262d;
  align-items: center;
  font-size: 0.9rem;
  margin: 0.25rem;
  line-height: 3.5rem;
}

/* Skillbars */
.skillBars {
  list-style: none;
  padding: 1rem 0 1rem 0;
  margin: 0 10% 0 10%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.skillBars li {
  margin-bottom: 1rem;
}

.skillTitle {
  text-align: left;
  font-size: 0.9rem;
  margin: 0;
}

.progressBar {
  background-color: #0d1117;
  border-radius: 1rem;
}

.progressBarProgress {
  height: 0.8rem;
  background-color: #ffa500;
  color: #0d1117;
  border-radius: 1rem;
  font-size: 0.6rem;
  font-weight: bold;
  text-align: center;
}

/* Contact */
.contact {
  display: grid;
  grid-template-columns: 20% 20% 20%;
  column-gap: 20%;
  list-style: none;
  text-align: center;
  padding: 0 25% 0 25%;
}

.contact li {
  background-color: #0d1117;
  border-radius: 0.4rem;
  height: 2.5rem;
  width: 2.5rem;
}

.contactIcon {
  height: 2.5rem;
  filter: invert(1);
}

.contact p {
  height: auto;
  width: auto;
}

.contactInfo {
  color: #8b949e;
  font-size: 0.8rem;
  text-align: center;
  padding-bottom: 3rem;
}

/* Media Queries */

@media only screen and (max-width: 1200px) {
  .skills {
    height: auto;
    width: 105%;
    margin: 2rem 0 0 0%;
  }

  .about,
  .languages,
  .skillBars,
  .contactInfo {
    margin-left: 20%;
    margin-right: 20%;
  }

  .about {
    font-size: 1.2rem;
  }

  .languages li {
    margin: 0 40% 0.5rem 40%;
  }

  .swedishProgress,
  .englishProgress {
    height: 3.5rem;
    width: 3.5rem;
  }

  .languages p {
    height: 3rem;
    width: 3rem;
    line-height: 3rem;
    margin: 0.25rem;
  }

  .contact {
    padding: 0 35% 0 35%;
  }
}

@media only screen and (max-width: 600px) {
  .skills {
    width: 90%;
    margin: 0 0 0 5%;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .languages li {
    margin: 0 35% 0.5rem 35%;
  }

  .languages p {
    background-color: #0d1117;
  }

  .progressBar,
  .progressBarProgress {
    height: 1rem;
    font-size: 0.7rem;
  }
}
